<template>
  <Header :nav="1" />
  <div class="page">
    <swiper
      :autoplay="{
        autoplay: true,
        delay: 4000,
        disableOnInteraction: false,
      }"
      :pagination="{
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletClass: 'my-bullet',
        bulletActiveClass: 'my-bullet-active',
      }"
      loop
      :slides-per-view="1"
      :space-between="0"
    >
      <swiper-slide
        class="page_slide"
        v-for="(item, index) in list"
        :key="index"
      >
        <div
          class="page_img"
          :style="{ 'background-image': 'url(' + item + ')' }"
        />
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
  <div class="page_auto">
    <div class="search_area">
      <p class="search_title">热搜关键词：</p>
      <p class="search_word">
        公司介绍&nbsp;&nbsp;半导体设备&nbsp;&nbsp;红外设备
      </p>
      <div class="search_frame">
        <input class="search_input" placeholder="搜索您感兴趣的内容" />
        <div class="clean" />
        <div class="search_icon" />
        <div class="line" />
      </div>
    </div>
    <div class="profile">
      <div class="profile_icon" />
      <div class="profile_line1">KEYWORD</div>
      <div class="profile_line2">
        EMMI / Thermal EMMI / 微光显微镜 / 锁相红外热像仪 / LIT设备 /
        电性失效分析 / lock-in thermography
      </div>
    </div>
    <div class="news_area">
      <div class="news">
        <div class="news_title">公司动态</div>
        <div class="news_frame">
          <p
            class="news_detail"
            v-for="news in news_list"
            :key="news"
            @click="go_detail(news.id)"
          >
            <span>{{ news.title }}</span>
          </p>
        </div>
      </div>
      <div class="more" @click="go_news()" />
    </div>
    <div class="video_frame">
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
        @ended="onPlayerEnded($event)"
        v-if="play_video"
      />
      <div class="play_btn" @click="play_btn()" />
    </div>
  </div>
  <div class="follow_us">
    <div class="follow_us_content">
      <div class="follow_word" />
      <div class="qr_code1" />
      <div class="right" />
    </div>
  </div>
  <div class="business" />
  <div class="business_frame">
    <div class="business_tab">
      <div class="tab1" @click="set_tab(1)">失效分析设备</div>
      <div class="tab2" @click="set_tab(2)">生物成像设备</div>
      <div class="tab_window" v-if="tab === 1">
        <div class="tab_detail">
          <span class="detail_notes" @click="go_product(1)"
            >- 锁相红外显微镜</span
          >
          <span class="detail_notes" @click="go_product(3)"
            >- InGaAs微光显微镜</span
          >
          <span class="detail_notes" @click="go_product()"
            >- Obirch（Coming soon）</span
          >
          <div class="detail_btn" @click="go_product()" />
        </div>
        <div class="product" />
      </div>
      <div class="tab_window" v-if="tab === 2"></div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player/src";
import { useRouter } from "vue-router";
import { GET_NEWS } from "../http/api";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    Header,
    Footer,
    videoPlayer,
  },
  data() {
    return {
      news_list: [],
      list: [
        // require("../assets/banner1-1.png"),
        require("../assets/banner1-2.png"),
        require("../assets/banner1-3.png"),
        require("../assets/banner1-4.png"),
      ],
      playerOptions: {
        muted: true,
        fluid: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://static.campaignchowsangsang.com/others/lg.mp4",
          },
        ],
        poster:
          "https://static.campaignchowsangsang.com/others/video_cover.png",
      },
      tab: 1,
      play_video: false,
    };
  },
  setup() {
    const router = useRouter();
    const jump_page = (page_name, value, query) => {
      router.push({
        name: page_name,
        params: {
          id: value,
        },
        query: {
          id: query,
        },
      });
    };
    return {
      jump_page,
    };
  },
  created: function () {
    GET_NEWS("page_size=5").then((res) => {
      this.news_list = res.results;
    });
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    play_btn() {
      this.play_video = true;
      setTimeout(() => {
        this.$refs.videoPlayer.player.play();
      }, 200);
    },
    // 视频播完回调
    onPlayerEnded(event) {
      console.log(event);
      this.play_video = false;
    },
    set_tab(i) {
      if (this.tab !== i) {
        this.tab = i;
      }
    },
    go_product(e) {
      if (e) {
        this.jump_page("Product", null, e);
      } else {
        this.jump_page("Product");
      }
    },
    go_news() {
      this.jump_page("News");
    },
    go_detail(id) {
      this.jump_page("Detail", id);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
}

.page_slide {
  width: 100%;
  height: 472px;
  .page_img {
    width: 100%;
    height: 472px;
    background: center center no-repeat;
    background-size: cover;
  }
}

.swiper-pagination {
  position: absolute;
  top: 430px;
  right: 2%;
  bottom: auto;
  left: auto;
  width: 60%;
  height: 15px;
  text-align: right;
  ::v-deep .my-bullet {
    width: 14px;
    height: 14px;
    margin: 0 22px 0 0;
    display: inline-block;
    background: url("../assets/dot.png") center center no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  ::v-deep .my-bullet-active {
    background: url("../assets/dot_select.png") center center no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}

.page_auto {
  width: 858px;
  margin: 0 auto;
  text-align: left;
  .search_area {
    position: relative;
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #aaaaaa;
    .search_title {
      font-size: 16px;
      line-height: 38px;
      margin-top: 31px;
      color: #323044;
      display: inline-block;
    }
    .search_word {
      font-size: 16px;
      line-height: 38px;
      margin-top: 31px;
      color: #aaaaaa;
      display: inline-block;
    }
    .search_frame {
      position: absolute;
      top: 31px;
      right: 0;
      width: 444px;
      height: 38px;
      border: 1px solid #323044;
      .search_input {
        width: 329px;
        height: 38px;
        font-size: 16px;
        color: #323044;
        line-height: 38px;
        padding-left: 15px;
        padding-right: 100px;
      }
      .clean {
        position: absolute;
        top: 0;
        right: 60px;
        width: 17px;
        height: 17px;
        padding: 10px;
        background: url("../assets/clean.png") center center no-repeat;
        background-size: 17px auto;
        cursor: pointer;
      }
      .line {
        position: absolute;
        top: 5px;
        right: 52px;
        width: 1px;
        height: 29px;
        background-color: #323044;
      }
      .search_icon {
        position: absolute;
        top: 0;
        right: 10px;
        width: 17px;
        height: 17px;
        padding: 10px;
        background: url("../assets/search.png") center center no-repeat;
        background-size: 17px auto;
        cursor: pointer;
      }
    }
  }
  .profile {
    position: relative;
    width: 100%;
    height: 180px;
    .profile_icon {
      position: absolute;
      top: 51px;
      left: 0;
      width: 40px;
      height: 73px;
      background: url("../assets/profile.png") center center no-repeat;
      background-size: contain;
    }
    .profile_line1 {
      position: absolute;
      top: 51px;
      left: 54px;
      font-size: 32px;
      color: #323044;
      line-height: 32px;
    }
    .profile_line2 {
      position: absolute;
      bottom: 42px;
      left: 54px;
      font-size: 24px;
      color: #323044;
      line-height: 24px;
      > span {
        font-size: 18px;
      }
    }
  }
  .news_area {
    position: relative;
    width: 100%;
    height: 211px;
    .news {
      width: 549px;
      height: 211px;
      padding-left: 22px;
      background: url("../assets/news.png") center center no-repeat;
      background-size: contain;
      float: left;
      .news_title {
        font-size: 30px;
        color: #c31920;
        line-height: 30px;
        padding-left: 36px;
        margin-top: 40px;
        margin-bottom: 10px;
        background: url("../assets/news_dot.png") 4px center no-repeat;
        background-size: 18px auto;
      }
      .news_frame {
        width: 486px;
        height: 112px;
        overflow: hidden;
      }
      .news_detail {
        width: 450px;
        font-size: 14px;
        color: #323044;
        line-height: 28px;
        padding-left: 36px;
        background: url("../assets/news_dot.png") 9px 10px no-repeat;
        background-size: 8px auto;
        > span {
          text-decoration: underline;
          text-underline-offset: 4px;
          &:hover {
            color: #c31920;
          }
        }
      }
    }
    .more {
      width: 259px;
      height: 211px;
      background: url("../assets/more.png") center center no-repeat;
      background-size: contain;
      float: right;
    }
  }
  .video_frame {
    position: relative;
    width: 858px;
    height: 480px;
    margin-bottom: 53px;
    background: url("../assets/video_cover.png") center center no-repeat;
    background-size: contain;
    .play_btn {
      position: absolute;
      top: 319px;
      left: 398px;
      width: 64px;
      height: 63px;
      background: url("../assets/play_btn.png") center center no-repeat;
      background-size: contain;
      z-index: 3;
    }
    .video-player-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 858px;
      height: 480px;
      z-index: 5;
    }
  }
}

.follow_us {
  width: 100%;
  height: 254px;
  background: url("../assets/follow_us.png") center center repeat-x;
  background-size: auto 100%;
  .follow_us_content {
    position: relative;
    width: 858px;
    height: 254px;
    margin: 0 auto;
    .follow_word {
      position: absolute;
      top: 80px;
      left: 20px;
      width: 196px;
      height: 80px;
      background: url("../assets/follow_word.png") center center no-repeat;
      background-size: contain;
    }
    .qr_code1 {
      position: absolute;
      top: 44px;
      left: 564px;
      width: 150px;
      height: 185px;
      background: url("../assets/qrcode1.png") center center no-repeat;
      background-size: contain;
    }
    .right {
      position: absolute;
      top: 34px;
      right: 0;
      width: 26px;
      height: 185px;
      background: url("../assets/right.png") center center no-repeat;
      background-size: contain;
    }
  }
}

.business {
  width: 100%;
  height: 254px;
  background: url("../assets/business.png") center center no-repeat;
  background-size: 100% auto;
}

.business_frame {
  width: 100%;
  height: 635px;
  margin-top: -1px;
  padding-top: 1px;
  background: #323044 url("../assets/business_bg.png") center center no-repeat;
  background-size: auto 100%;
}

.business_tab {
  position: relative;
  width: 672px;
  height: 450px;
  margin: 92px auto;
  background-color: #ffffff;
  .tab1 {
    width: 336px;
    height: 84px;
    font-size: 23px;
    color: #ffffff;
    line-height: 84px;
    text-align: center;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
    background-color: #bb1b21;
    float: left;
  }
  .tab2 {
    width: 336px;
    height: 84px;
    font-size: 23px;
    color: #ffffff;
    line-height: 84px;
    text-align: center;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
    background-color: #323044;
    float: right;
  }
  .tab_window {
    position: relative;
    width: 672px;
    height: 366px;
    clear: both;
    margin-top: -1px;
    padding-top: 1px;
    .tab_detail {
      width: 270px;
      height: 260px;
      padding-top: 20px;
      margin-left: 90px;
      margin-top: 40px;
      text-align: left;
      .detail_notes {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 30px;
        color: #323044;
        border-bottom: 1px solid;
        padding-bottom: 5px;
        display: inline-block;
        &:hover {
          color: #c31920;
        }
      }
      .detail_btn {
        width: 160px;
        height: 40px;
        margin-top: 16px;
        background: url("../assets/detail_btn.png") center center no-repeat;
        background-size: contain;
      }
    }
    .product {
      position: absolute;
      top: 48px;
      right: 51px;
      width: 236px;
      height: 266px;
      background: url("../assets/product1.png") center center no-repeat;
      background-size: contain;
    }
  }
}
</style>
