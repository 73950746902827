<template>
  <div id="foot">
    <div class="foot_content">
      <div class="foot_left">
        <div class="foot_nav" @click="go_about_us()">关于我们</div>
        <div class="foot_nav" @click="go_product()">产品详情</div>
        <div class="foot_nav" style="margin-right: 30px" @click="contact_us()">
          联系我们
        </div>
      </div>
      <div class="foot_right">
        <p class="copy_right">苏州凌光红外科技有限公司</p>
        <p class="copy_right1">苏ICP备2023003870号</p>
        <div class="qrcode5" />
      </div>
      <div class="back_top" @click="go_top()">返回顶部</div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { useRouter } from "vue-router/dist/vue-router";

export default {
  name: "Footer",
  setup() {
    const router = useRouter();
    const jump_page = (page_name, value) => {
      router.push({
        name: page_name,
        params: {
          value: JSON.stringify(value),
        },
      });
    };
    return {
      jump_page,
    };
  },
  methods: {
    go_top() {
      VueScrollTo.scrollTo(`body`);
    },
    go_about_us() {
      this.jump_page("About");
    },
    contact_us() {
      this.jump_page("ContactUs");
    },
    go_product() {
      this.jump_page("Product");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#foot {
  position: relative;
  width: 100%;
  height: 392px;
  background-color: #323232;
  .foot_content {
    position: relative;
    width: 860px;
    margin: -1px auto 0;
    padding-top: 1px;
    z-index: 3;
    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .foot_left {
      width: 530px;
      height: 140px;
      margin-top: 136px;
      border-right: 1px solid #ffffff;
      float: left;
      .foot_nav {
        width: 4em;
        height: 18px;
        margin-bottom: 46px;
        margin-right: 81px;
        font-size: 18px;
        color: #ffffff;
        line-height: 18px;
        cursor: pointer;
        float: left;
      }
    }
    .foot_right {
      width: 320px;
      height: 140px;
      margin-top: 136px;
      float: right;
      .copy_right {
        font-size: 18px;
        color: #ffffff;
        line-height: 18px;
        margin-left: 100px;
        text-align: left;
        float: left;
      }
      .copy_right1 {
        font-size: 18px;
        color: #ffffff;
        line-height: 30px;
        margin-left: 100px;
        margin-top: 2px;
        text-align: left;
        float: left;
      }
      .qrcode5 {
        width: 80px;
        height: 100px;
        margin-top: 4px;
        margin-right: 85px;
        background: url("../assets/qrcode5.png") center center no-repeat;
        background-size: contain;
        float: right;
      }
    }
    .back_top {
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
      margin-top: 30px;
      padding-right: 30px;
      background: url("../assets/btn_backtotop.png") center right no-repeat;
      background-size: auto 18px;
      float: right;
    }
  }
}
</style>
